import { NgClass } from "@angular/common";
import { Component, effect, inject, output } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { NavigationEnd, Router } from "@angular/router";
import {
   IconComponent,
   PopoverDirective,
   TooltipDirective,
   isNativeMobileApp,
} from "@limblecmms/lim-ui";
import Median from "median-js-bridge";
import { filter, map, startWith } from "rxjs";
import { UserImage } from "src/app/files/components/userImage/userImage.element.component";
import { ThemingService } from "src/app/settings/services/themingService";
import { FailedEmailBanner } from "src/app/shared/components/global/banners/failedEmailBannerElement/failedEmailBanner.element.component";
import { AccountManagementPopover } from "src/app/shared/components/global/global-nav/account-management-popover/account-management-popover.component";
import { NotificationsPopover } from "src/app/shared/components/global/global-nav/notifications-popover/notifications-popover.element.component";
import { MobileHeaderTitleService } from "src/app/shared/components/headers-and-footers/mobile-header/mobile-header-title.service";
import { ManageEmails } from "src/app/shared/services/manageEmails";
import { RoutingService } from "src/app/shared/services/routing/routing.service";
import { WhiteLabelService } from "src/app/shared/services/white-label/white-label.service";
import type { SmtpSettings } from "src/app/shared/types/general.types";
import { ManageUser } from "src/app/users/services/manageUser";

@Component({
   selector: "mobile-header",
   templateUrl: "./mobile-header.component.html",
   styleUrls: ["./mobile-header.component.scss"],
   standalone: true,
   imports: [
      NgClass,
      IconComponent,
      FailedEmailBanner,
      NotificationsPopover,
      TooltipDirective,
      PopoverDirective,
      UserImage,
      AccountManagementPopover,
   ],
})
export class MobileHeaderComponent {
   public smtpSettings: SmtpSettings = {
      enabled: 0,
      itContactEmail: null,
      smtpServer: null,
      smtpUsername: null,
      smtpFromAddress: null,
      smtpFromName: null,
      smtpPassword: null,
      smtpPort: null,
      smtpSSLTLS: null,
      smtpSTARTTLS: null,
      emailsFailing: 0,
   };
   public currentUser;
   public showFeatureRequestBoard = false;
   public mobilePageTitleColor = "grey";
   public readonly menuClick = output<null>();
   public title = inject(MobileHeaderTitleService).title;
   private currentRouteUrl: string = "";
   private readonly window: any = window;
   private readonly themingService = inject(ThemingService);

   public constructor() {
      const whiteLabelService = inject(WhiteLabelService);
      const manageEmails = inject(ManageEmails);
      const routingService = inject(RoutingService);
      inject(ManageUser).currentUserChanges$.subscribe((currentUser) => {
         this.currentUser = currentUser;
         this.showFeatureRequestBoard = whiteLabelService.shouldShowFeatureRequestBoard();
         if (!this.currentUser?.userInfo?.featureCustomSmtp) return;
         manageEmails.getSmtpSettings().then((answer) => {
            if (!answer) return;
            this.smtpSettings = answer;
         });
      });
      inject(Router)
         .events.pipe(
            takeUntilDestroyed(),
            filter((event): event is NavigationEnd => event instanceof NavigationEnd),
            map((event) => event.url),
            startWith(inject(Router).url),
         )
         .subscribe((url) => {
            const routeNames = routingService.getAllRouteNames();
            this.currentRouteUrl = url;
            this.updateStatusBarTheme(this.themingService.theme());
            if (routeNames.some((name) => name === "mobileTasks")) {
               this.mobilePageTitleColor = "green";
            } else {
               this.mobilePageTitleColor = "grey";
            }
         });
      effect(() => {
         this.updateStatusBarTheme(this.themingService.theme());
      });
   }

   public toggleShowMobileSideMenu() {
      this.menuClick.emit(null);
   }

   private updateStatusBarTheme(theme: "light" | "dark"): void {
      if (!isNativeMobileApp()) {
         return;
      }
      if (this.currentRouteUrl.includes("mobileTasks")) {
         this.setStatusBarColor("ff289e49", "light");
         return;
      }
      const backgroundColor = theme === "dark" ? "17181a" : "fff6f6f6";
      const iconThemeColor = theme === "dark" ? "dark" : "light";
      this.setStatusBarColor(backgroundColor, iconThemeColor);
   }

   /**
    * This method controls the colors of the top sliver of the phone screen,
    * containing the clock, cellular connection, battery life, etc.
    */
   private setStatusBarColor(
      backgroundColor: string,
      iconThemeColor: "light" | "dark" | "auto",
   ): void {
      Median.statusbar.set({
         style: iconThemeColor,
         color: backgroundColor,
         overlay: false,
         blur: false,
      });
   }
}
