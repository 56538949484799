import { inject, Injectable } from "@angular/core";
import { isMobile } from "@limblecmms/lim-ui";
import axios from "axios/dist/axios";
import type { Observable } from "rxjs";
import { filter } from "rxjs/operators";
import { WebsocketService } from "src/app/shared/services/websockets/websocket.service";
import type { WebsocketMessage } from "src/app/shared/types/websocket.types";
import { ManageLogin } from "src/app/users/services/manageLogin";

@Injectable({ providedIn: "root" })
export class HammerService {
   /** Emits "hammer" messages when received via websocket */
   public readonly hammer$: Observable<WebsocketMessage>;
   /** Emits "stopHammer" messages when received via websocket */
   public readonly stopHammer$: Observable<WebsocketMessage>;
   /** Emits "sledgeHammer" messages when received via websocket */
   public readonly sledgeHammer$: Observable<WebsocketMessage>;

   private readonly websocketService = inject(WebsocketService);
   private readonly manageLogin = inject(ManageLogin);

   public constructor() {
      this.hammer$ = this.websocketService.messages$.pipe(
         filter((msg) => msg.action === "hammer"),
      );
      this.stopHammer$ = this.websocketService.messages$.pipe(
         filter((msg) => msg.action === "stopHammer"),
      );
      this.sledgeHammer$ = this.websocketService.messages$.pipe(
         filter((msg) => msg.action === "sledgeHammer"),
      );
   }

   /**
    * Creates the subscriptions that can cause other users to be logged out
    * and may cause the current user to be logged out by them
    */
   public start(): void {
      this.websocketService.socketReady$.subscribe(() => {
         this.passHammer();
      });
      this.sledgeHammer$.subscribe(() => {
         //another tab has decided to logout immediately
         this.manageLogin.logout();
      });
   }

   /** want to stay logged in here, pass the hammer to different session */
   public passHammer(): void {
      const connectionId = this.websocketService.getConnectionId();
      const sessionId = this.websocketService.getSessionID();
      axios({
         method: "POST",
         url: "phpscripts/manageLogin.php",
         params: { action: "checkAccountSharing" },
         data: {
            connectionID: connectionId,
            sessionID: sessionId,
            mobile: isMobile() ? "true" : "false",
         },
      });
   }
}
