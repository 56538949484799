import { NgClass } from "@angular/common";
import { Component, computed, inject, type Signal } from "@angular/core";
import { toSignal } from "@angular/core/rxjs-interop";
import { NavigationEnd, Router } from "@angular/router";
import { IconComponent, LimbleHtmlDirective, ModalService } from "@limblecmms/lim-ui";
import { filter } from "rxjs";
import { StartWOService } from "src/app/dashboards/widgets/list/tasks/dashboard-list-view-tasks/start-wo/start-wo.service";
import { ManageLang } from "src/app/languages/services/manageLang";
import { GlobalSearchComponent } from "src/app/shared/components/global/globalSearch/globalSearch.component";
import { AlertService } from "src/app/shared/services/alert.service";
import { ParamsService } from "src/app/shared/services/params.service";
import { RoutingService } from "src/app/shared/services/routing/routing.service";
import { CredService } from "src/app/users/services/creds/cred.service";
import { ManageUser } from "src/app/users/services/manageUser";

@Component({
   selector: "mobile-footer",
   templateUrl: "./mobile-footer.component.html",
   styleUrls: ["./mobile-footer.component.scss"],
   standalone: true,
   imports: [NgClass, IconComponent, LimbleHtmlDirective],
})
export class MobileFooterComponent {
   private readonly credService = inject(CredService);
   private readonly alertService = inject(AlertService);
   private readonly manageUser = inject(ManageUser);
   private readonly router = inject(Router);
   private readonly startWOService = inject(StartWOService);
   private readonly modalService = inject(ModalService);
   private readonly paramsService = inject(ParamsService);
   protected readonly lang = inject(ManageLang).lang;
   protected readonly active: Signal<
      "mobileTasks" | "customDashboards" | "home" | undefined
   >;

   public constructor() {
      const routingService = inject(RoutingService);
      const navigationEnd = toSignal(
         this.router.events.pipe(
            filter((event): event is NavigationEnd => event instanceof NavigationEnd),
         ),
      );
      this.active = computed(() => {
         navigationEnd();
         const routeNames = routingService.getAllRouteNames();
         if (routeNames.some((name) => name === "mobileTasks")) {
            return "mobileTasks";
         } else if (routeNames.some((name) => name === "customDashboardMobile")) {
            return "customDashboards";
         }
         return "home";
      });
   }

   protected gotoCustomDashboard() {
      if (
         !this.credService.checkCredAnywhere(
            this.credService.Permissions.ViewCustomDashboards,
         )
      ) {
         this.alertService.addAlert(this.lang()?.missingCred41 ?? "", "danger", 10000);
         return;
      }
      const customDashboard =
         this.manageUser.getCurrentUser().userInfo.userUIPreferences.currentDashboardID ||
         0;
      this.router.navigate(["/customDashboardMobile", customDashboard]);
   }

   protected gotoOpenTasks() {
      if (
         !(
            this.credService.checkCredAnywhere(
               this.credService.Permissions.ViewMyOpenTasks,
            ) ||
            this.credService.checkCredAnywhere(
               this.credService.Permissions.ViewAllOpenTasks,
            )
         )
      ) {
         if (
            !this.credService.checkCredAnywhere(
               this.credService.Permissions.ViewAllOpenTasks,
            )
         ) {
            this.alertService.addAlert(this.lang()?.missingCred35 ?? "", "danger", 10000);
         }
         return;
      }
      this.router.navigate(["/mobileTasks"]);
   }

   protected goHome() {
      this.router.navigate(["/mobileDashboard"]);
   }

   protected startWorkOrder() {
      this.startWOService.startWorkOrder();
   }

   protected showGlobalSearch(): void {
      const instance = this.modalService.open(GlobalSearchComponent);
      this.paramsService.params = {
         modalInstance: instance,
      };
   }
}
