import { inject, Injectable } from "@angular/core";
import type { AxiosResponse } from "axios/dist/axios";
import axios from "axios/dist/axios";
import type { Observable } from "rxjs";
import type {
   ListResponse,
   Pagination,
} from "src/app/shared/services/flannel-api-service";
import type {
   GlobalSearchSettings,
   GlobalSearchSettingsDto,
} from "src/app/shared/types/general.types";
import type { TaskSortOptions } from "src/app/tasks/components/shared";
import { TasksApiService } from "src/app/tasks/components/shared";
import type { TaskEntity } from "src/app/tasks/components/shared/services/tasks-api/task-api.models";
import { environment } from "src/environments/environment";

export type GlobalSearchResponse = {
   tasks: Array<number>;
   pms: Array<number>;
   assets: Array<number>;
   parts: Array<number>;
   pos: Array<number>;
   vendors: Array<number>;
};

@Injectable({ providedIn: "root" })
export class GlobalSearchService {
   private readonly tasksApiService = inject(TasksApiService);

   public getGlobalSearchTaskResults(
      search: string,
      sort: TaskSortOptions,
      pagination: Pagination,
   ): Observable<Partial<ListResponse<TaskEntity>>> {
      return this.tasksApiService.getList({
         sort,
         pagination,
         params: {
            search: search,
            columns: "comments,commentsDetails,completedBy,completionColor,assets",
            includeDeletedAssets: true,
         },
      });
   }

   public async getGlobalSearchResults(
      search: string,
      globalSearchSettings: GlobalSearchSettings,
   ): Promise<AxiosResponse<GlobalSearchResponse>> {
      return axios.get(`${environment.flannelUrl}/globalSearch`, {
         params: {
            search,
            globalSearchSettings: this.globalSearchSettingsToDto(globalSearchSettings),
         },
      });
   }

   public async getGlobalSearchSettings(): Promise<GlobalSearchSettingsDto | null> {
      const answer = await axios.get<GlobalSearchSettingsDto | null>(
         `${environment.flannelUrl}/globalSearch/settings`,
      );
      return answer.data;
   }

   public async updateGlobalSearchSettings(
      globalSearchSettings: GlobalSearchSettings | null,
   ): Promise<AxiosResponse> {
      return axios.put(`${environment.flannelUrl}/globalSearch/settings`, {
         ...this.globalSearchSettingsToDto(globalSearchSettings),
      });
   }

   private globalSearchSettingsToDto(
      settings: GlobalSearchSettings | null,
   ): GlobalSearchSettingsDto | null {
      if (settings == null) {
         return null;
      }
      const settingsDto = {};
      for (const entity in settings) {
         settingsDto[entity] = Number(settings[entity]);
      }
      return settingsDto as GlobalSearchSettingsDto;
   }
}
