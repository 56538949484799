import type { OnInit } from "@angular/core";
import { inject, Component, Input, computed } from "@angular/core";
import { RouterLink } from "@angular/router";
import { ModalService, SecondaryButtonComponent } from "@limblecmms/lim-ui";
import moment from "moment";
import { ManageLang } from "src/app/languages/services/manageLang";
import { PopupUpsellModal } from "src/app/shared/components/global/popupUpsellModal/popup-upsell-modal.component";
import { ParamsService } from "src/app/shared/services/params.service";

@Component({
   selector: "trial-banner-message",
   templateUrl: "./trialBannerMessage.component.html",
   styleUrls: ["./trialBannerMessage.component.scss"],
   standalone: true,
   imports: [SecondaryButtonComponent, PopupUpsellModal, RouterLink],
})
export class TrialBannerMessageComponent implements OnInit {
   @Input() daysLeft!: number;
   @Input() hoursLeft!: number;
   @Input() minutesLeft!: number;
   @Input() timeLeftInMS!: number;
   @Input() tierText: string | undefined;
   @Input() trialTier: string | undefined;

   private readonly modalService = inject(ModalService);
   private readonly paramsService = inject(ParamsService);
   private readonly manageLang = inject(ManageLang);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public ngOnInit() {
      const trialEndingPopupLastShown =
         localStorage.getItem("trialEndingPopupLastShown") ?? undefined;

      this.daysLeft = Math.floor(moment.duration(this.timeLeftInMS).asDays());

      if (
         trialEndingPopupLastShown !== moment().format("YYYY-MM-DD") &&
         this.lang &&
         this.daysLeft >= 0 &&
         this.daysLeft < 8 &&
         (this.trialTier == "enterprise" || this.trialTier == "premium")
      ) {
         const instance = this.modalService.open(PopupUpsellModal);
         this.paramsService.params = {
            modalInstance: instance,
            resolve: {
               data: {
                  icon: "hourglassClockRegular",
                  title: this.lang()[`YourTrialWillEndIn${this.daysLeft}Days`],
                  message: this.lang().IfYouDontUpgradeYourAccountYouWillLoseTheFollowing,
                  iconColor: "success",
                  iconSize: "extra-large",
                  actionText: this.lang().ContactUs,
                  cancelText: this.lang().Cancel,
                  actionLink: "https://limblecmms.com/pricing/",
                  listType: this.trialTier,
                  daysLeft: this.daysLeft,
               },
            },
         };

         localStorage.setItem("trialEndingPopupLastShown", moment().format("YYYY-MM-DD"));
      }
   }
}
