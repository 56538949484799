import type { PipeTransform } from "@angular/core";
import { Pipe } from "@angular/core";
import type { Aliases } from "@limblecmms/lim-ui";
import { NotificationHistoryType } from "src/app/shared/types/general.types";

@Pipe({
   name: "notificationIcon",
   standalone: true,
})
export class NotificationIconPipe implements PipeTransform {
   public transform(notification: any, iconType: string): Aliases {
      if (iconType === "secondary") {
         return this.getSecondaryNotificationIcon(notification);
      }

      return this.getPrimaryNotificationIcon(notification);
   }

   protected getPrimaryNotificationIcon(notification: any): Aliases {
      switch (notification.type) {
         case 1:
         case NotificationHistoryType.DASHBOARD: // dashboard
            return "comment";
         case 2:
         case 6:
         case 7:
         case 9:
            return "squareArrowUpRight";
         case 3:
            return "envelope";
         case 4:
            return "squareCheck";
         case 5:
         case 12:
            return "clock";
         case 8:
         case 10:
         case 14:
            return "thumbsDown";
         case 11:
            return "bolt";
         case 13:
            return "thumbsUp";
         case 15:
            return "screwdriverWrench";
         case NotificationHistoryType.PRICING:
            return "stars";
         default:
            return "squareArrowUpRight";
      }
   }

   protected getSecondaryNotificationIcon(notification: any): Aliases {
      if (notification.checklistID > 0) {
         switch (notification.checklistTemplateOld) {
            case 1:
               return "wrench";
            case 2:
               if (
                  !(
                     notification.checklistBatchID == 10000 ||
                     notification.checklistBatchID == 300112
                  )
               ) {
                  return "wpforms";
               } else if (
                  notification.checklistBatchID == 10000 ||
                  notification.checklistBatchID == 300112
               ) {
                  return "triangleExclamation";
               }
               break;
            case 4:
               return "file";
            case 5:
               return "gears";
            default:
               return "list";
         }
      } else if (notification.poID > 0) {
         return "filePowerpoint";
      } else if (notification.prID > 0) {
         return "fileLines";
      } else if (notification.assetID > 0) {
         return "screwdriverWrench";
      }

      return "list";
   }
}
