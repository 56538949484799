import { AsyncPipe, NgClass, NgTemplateOutlet } from "@angular/common";
import type { OnDestroy, ElementRef } from "@angular/core";
import {
   inject,
   ChangeDetectorRef,
   Component,
   NgZone,
   ViewChild,
   computed,
} from "@angular/core";
import { Title } from "@angular/platform-browser";
import { RoutesRecognized, Router, RouterOutlet } from "@angular/router";
import {
   AlertComponent,
   IconComponent,
   LimUiDatepickerConfig,
   LimUiDatepickerI18n,
   ModalService,
   LimbleHtmlDirective,
   ModalComponent,
   ModalDirective,
   OutlinedButtonComponent,
   PrimaryButtonComponent,
   isMobile,
   manageDemo,
} from "@limblecmms/lim-ui";
import $ from "jquery";
import Cookies from "js-cookie";
import type { Observable, Subscription } from "rxjs";
import {
   combineLatestWith,
   debounceTime,
   distinctUntilChanged,
   filter,
   firstValueFrom,
   from,
   fromEvent,
   map,
   merge,
   shareReplay,
   throttleTime,
} from "rxjs";
import { ManageLang } from "src/app/languages/services/manageLang";
import { ConnectionService } from "src/app/lite/connection/connection.service";
import { LitePropsStorageSyncService } from "src/app/lite/local-db/resources/properties/lite-props/lite-props.storage.sync.service";
import { syncQueue } from "src/app/lite/local-db/resources/queue/sync/sync.manager";
import { ConnectionLostComponent } from "src/app/lite/ui/connection-lost/connection-lost.component";
import { PushNotificationService } from "src/app/mobile/push-notifications/push-notification.service";
import { ThemingService } from "src/app/settings/services/themingService";
import { AlertPopup } from "src/app/shared/components/global/alertModal/alertPopup.modal.component";
import { GlobalNavComponent } from "src/app/shared/components/global/global-nav/global-nav.component";
import { GlobalSearchComponent } from "src/app/shared/components/global/globalSearch/globalSearch.component";
import { MobileGlobalNav } from "src/app/shared/components/global/mobileGlobalNav/mobileGlobalNav.wrapper.component";
import { AppHeaderComponent } from "src/app/shared/components/headers-and-footers/app-header/app-header.component";
import { MobileFooterComponent } from "src/app/shared/components/headers-and-footers/mobile-footer/mobile-footer.component";
import { MobileHeaderComponent } from "src/app/shared/components/headers-and-footers/mobile-header/mobile-header.component";
import { AlertService, type Alert } from "src/app/shared/services/alert.service";
import { ManageObservables } from "src/app/shared/services/manageObservables";
import { ParamsService } from "src/app/shared/services/params.service";
import { RefreshService } from "src/app/shared/services/refresh.service";
import { RoutingService } from "src/app/shared/services/routing/routing.service";
import { HammerService } from "src/app/shared/services/websockets/hammer.service";
import { WhiteLabelService } from "src/app/shared/services/white-label/white-label.service";
import type { Timer } from "src/app/shared/utils/app.util";
import { PopTask } from "src/app/tasks/components/popTaskModal/popTask.modal.component";
import { SetupWorkOrder } from "src/app/tasks/components/setupWorkOrderModal/setupWorkOrder.modal.component";
import { ManageTask } from "src/app/tasks/services/manageTask";
import { ManageProfile } from "src/app/users/services//manageProfile";
import { CredService } from "src/app/users/services/creds/cred.service";
import { ManageLogin } from "src/app/users/services/manageLogin";
import { ManageUser } from "src/app/users/services/manageUser";

@Component({
   selector: "app-housing",
   templateUrl: "./app-housing.component.html",
   styleUrls: ["./app-housing.component.scss"],
   standalone: true,
   imports: [
      ModalComponent,
      ModalDirective,
      PrimaryButtonComponent,
      OutlinedButtonComponent,
      NgClass,
      MobileGlobalNav,
      AlertComponent,
      LimbleHtmlDirective,
      IconComponent,
      AppHeaderComponent,
      MobileHeaderComponent,
      GlobalNavComponent,
      RouterOutlet,
      NgTemplateOutlet,
      MobileFooterComponent,
      AsyncPipe,
      ConnectionLostComponent,
   ],
})
export class AppHousingComponent implements OnDestroy {
   public window: any = window;
   public currentUser;
   public userImage;
   public customerID;
   public showFeatureRequestBoard;
   public user;
   public userID;
   protected alerts$: Observable<Array<Alert>>;
   public logout;
   public errorMsg;
   public userName;
   public hammers: { interval: Timer; timer: Timer } | undefined;
   public showHammerModal;
   public timeLeft;
   public mobile: boolean;
   public currentDate;
   public isExternalUser;
   public customerIDSub;
   public showMobileSideMenu = false;
   public loaded;
   protected dataLimitingMessage: string = "";
   protected showOfflineBanner$: Observable<boolean>;
   private readonly subscriptions: Array<Subscription> = [];
   protected readonly isWorkRequestPage = computed(() =>
      this.routingService.getAllRouteData().some((data) => data.workRequestPageCheck),
   );

   @ViewChild("paywallPopup") paywallPopup?: ElementRef<HTMLElement>;

   private readonly manageLang = inject(ManageLang);
   private readonly modalService = inject(ModalService);
   private readonly router = inject(Router);
   private readonly manageLogin = inject(ManageLogin);
   private readonly manageTask = inject(ManageTask);
   private readonly alertService = inject(AlertService);
   private readonly manageUser = inject(ManageUser);
   private readonly manageObservables = inject(ManageObservables);
   private readonly hammerService = inject(HammerService);
   private readonly paramsService = inject(ParamsService);
   private readonly manageProfile = inject(ManageProfile);
   private readonly titleService = inject(Title);
   private readonly datepickerConfig = inject(LimUiDatepickerConfig);
   private readonly datepickerI18N = inject(LimUiDatepickerI18n);
   private readonly ngZone = inject(NgZone);
   private readonly changeDetectorRef = inject(ChangeDetectorRef);
   private readonly themingService = inject(ThemingService);
   private readonly credService = inject(CredService);
   private readonly refreshService = inject(RefreshService);
   private readonly connectionService = inject(ConnectionService);
   private readonly whiteLabelService = inject(WhiteLabelService);
   private readonly litePropsStorageSyncService = inject(LitePropsStorageSyncService);
   private readonly pushNotificationService = inject(PushNotificationService);
   private readonly routingService = inject(RoutingService);

   public readonly lang = inject(ManageLang).lang;
   public languageStringArray = this.manageLang.languageStringArray;
   public constructor() {
      this.isExternalUser = window.location.href.includes("viewExternalTask");

      this.mobile = isMobile();

      const routeSecurity = this.router.events
         .pipe(
            filter(
               (event): event is RoutesRecognized => event instanceof RoutesRecognized,
            ),
         )
         .subscribe((event) => {
            const oldPath = this.router.url.split("?")[0];
            const newPath = event.urlAfterRedirects.split("?")[0];
            if (oldPath === newPath) {
               /* The route didn't change. We only want to check login (and
               potentially cause a logout) when the route changes, to minimize
               the chance of disruption. */
               return;
            }
            const requireLogin = this.routingService.loginRequired();
            if (requireLogin || this.isWorkRequestPage()) {
               this.manageLogin.checkLogin();
            }
         });

      if (!this.isMobileWorkRequest()) {
         this.refreshService.refreshData({ notify: false });
      }

      this.currentDate = new Date();

      this.showOfflineBanner$ = this.connectionService.isOnlineObs().pipe(
         map((isOnline) => isOnline === false),
         debounceTime(10),
         distinctUntilChanged(),
         shareReplay(1),
      );

      // Support the legacy m=true query param for mobile testing
      const demoScreenType = window.location.href.includes("m=true")
         ? "mobile"
         : "desktop";
      manageDemo.setDemoScreenType(demoScreenType);

      this.customerIDSub = this.manageObservables.setSubscription("customerID", (val) => {
         this.customerID = val;
      });

      this.window.limbleDown = "false";
      this.window.limbleDownTimeFrame = "6:00pm EST until 10:00pm EST";

      from(this.manageLang.calcLang(undefined))
         .pipe(combineLatestWith(this.manageUser.currentUserInitialized$))
         .subscribe(() => {
            this.logout = () => {
               this.errorMsg = "";
               this.manageLogin.logout();
            };

            //this section of code is so that when a user is on the same computer in the same browser they can't be logged into different accounts
            const noSame = setInterval(() => {
               const currentUser = this.manageUser.getCurrentUser();
               if (
                  Cookies.get("UID") === undefined &&
                  currentUser !== undefined &&
                  currentUser.gUserID === undefined
               ) {
                  //nothing is set so do nothing.
               } else if (window.location.hostname.includes("localhost")) {
                  clearInterval(noSame);
               } else if (Cookies.get("UID") != currentUser.gUserID) {
                  if (currentUser.gUserID === undefined) {
                     clearInterval(noSame);
                     const instance = this.modalService.open(AlertPopup);

                     this.paramsService.params = {
                        modalInstance: instance,
                        resolve: {
                           message: this.lang()?.CurrentlyLoggedInAnotherBrowserTabMsg,
                           title: this.lang()?.CurrentlyLoggedInAnotherBrowserTabTitle,
                        },
                     };

                     instance.result.then(
                        () => {
                           location.reload();
                        },
                        () => {
                           location.reload();
                        },
                     );
                  } else {
                     const check3 = window.location.href.includes(
                        "loggedInAsExternalUser",
                     );
                     if (check3) {
                        //if we are on this page don't worry about doing anything
                        this.isExternalUser = true;
                     } else {
                        location.reload(); //they have just timed out so
                     }
                  }
               }
            }, 3000);

            // Listen for cntrl + shift + f to open global search
            this.ngZone.runOutsideAngular(() => {
               document.addEventListener("keydown", (evt) => {
                  if (evt.ctrlKey && evt.shiftKey && evt.keyCode === 70) {
                     if (
                        this.manageUser.getCurrentUser() === undefined ||
                        this.manageUser.getCurrentUser() == false ||
                        this.manageUser.getCurrentUser().workOrderUser == 1
                     ) {
                        return;
                     }
                     this.showGlobalSearch();
                  }
               });
            });

            const statusCheckIntervalTime = 5000;
            let refreshTime = new Date().getTime();
            this.ngZone.runOutsideAngular(() => {
               merge(fromEvent(document, "keypress"), fromEvent(document, "mousedown"))
                  .pipe(throttleTime(statusCheckIntervalTime))
                  .subscribe(() => {
                     refreshTime = new Date().getTime();
                  });
            });

            const loginStatusCheck = () => {
               let timeoutTime;
               if (
                  this.manageUser.getCurrentUser() === undefined ||
                  this.manageUser.getCurrentUser() === "none"
               ) {
                  timeoutTime = (2 * 3600 + 400) * 1000; //2 hours plus a couple minutes converted to ms
               } else {
                  timeoutTime =
                     (this.manageUser.getCurrentUser().userInfo
                        .customerAutoLogOutTimeInHrs *
                        3600 +
                        400) *
                     1000; //2 hours plus a couple minutes converted to ms
               }

               //3700 seconds or 1 hour + 100 seconds **NOTE this should have the same time (acutally a little bit longer)
               //as backend timeout 3600 seconds
               if (new Date().getTime() - refreshTime >= timeoutTime) {
                  this.manageLogin.checkLogin().then((answer: any) => {
                     if (answer.data.success == true) {
                        //they are still logged in so keep checking
                        refreshTime = new Date().getTime();
                        setTimeout(() => {
                           loginStatusCheck();
                        }, statusCheckIntervalTime);
                     }
                  });
               } else {
                  //every 4 minutes check to see if they have timed out 240000
                  setTimeout(() => {
                     loginStatusCheck();
                  }, statusCheckIntervalTime);
               }
            };

            this.ngZone.runOutsideAngular(() => {
               setTimeout(() => {
                  loginStatusCheck();
               }, statusCheckIntervalTime);
            });

            this.ngZone.runOutsideAngular(() => {
               document.addEventListener("keypress", (event) => {
                  if (event.keyCode == 13) {
                     if ($(":focus").hasClass("exitOnEnterKeyPress")) {
                        event.preventDefault();
                        let myIndex = 0;

                        $("div[contenteditable='true']").each((index, element) => {
                           if ($(element).is(":focus")) {
                              myIndex = index;
                           }
                        });

                        if ($("div[contenteditable='true']")[myIndex + 1] === undefined) {
                           $("#loseFocus")[0].focus();
                        } else {
                           $("div[contenteditable='true']")[myIndex + 1].focus();
                        }
                        this.changeDetectorRef.detectChanges();
                        return false;
                     }
                  }
                  return true;
               });
            });

            this.preventAccountSharing();
         });

      const loginWatchVarSub = this.manageUser.currentUserChanges$.subscribe(
         (currentUser) => {
            this.currentUser = currentUser || false;

            if (this.currentUser) {
               if (this.currentUser.userInfo) {
                  this.showFeatureRequestBoard =
                     this.whiteLabelService.shouldShowFeatureRequestBoard();
                  this.userImage = false;
                  if (this.currentUser.userInfo.userImage != "") {
                     this.userImage = this.currentUser.userInfo.userImage;
                  }
                  this.customerID = this.currentUser.userInfo.customerID;
                  this.userID = this.currentUser.userInfo.userID;
                  if (this.user) {
                     if (this.user.userID !== this.userID) {
                        this.user.userID = this.userID;
                     }
                     if (this.user.userImage !== this.userImage) {
                        this.user.userImage = this.userImage;
                        this.user = { ...this.user };
                     }
                  } else {
                     this.user = {
                        userID: this.userID,
                        userImage: this.userImage,
                     };
                  }

                  this.datepickerConfig.customerDateFormat =
                     this.currentUser.userInfo.customerDateFormat ?? "YYYY/MM/DD";
                  this.datepickerConfig.startOfWeek = this.currentUser.userInfo
                     .customerStartOfWorkWeek
                     ? this.currentUser.userInfo.customerStartOfWorkWeek
                     : 1;

                  const localeStr = this.manageLang.getSelectedLocale();
                  if (localeStr) {
                     this.datepickerI18N.setCustomerLocale(localeStr);
                  }
                  if (this.currentUser.userInfo.userUIPreferences?.theme) {
                     this.themingService.setTheme(
                        this.currentUser.userInfo.userUIPreferences.theme,
                     );
                  } else {
                     this.themingService.setTheme("light");
                  }
                  if (this.currentUser.userInfo.dataLimitingNumberOfMonths === null) {
                     this.dataLimitingMessage = "";
                     (
                        document.querySelector(".bottom-banner") as HTMLElement
                     )?.classList.remove("show");
                  } else if (this.currentUser.userInfo.dataLimitingNumberOfMonths > 0) {
                     if (this.currentUser.userInfo.dataLimitingNumberOfMonths === 1) {
                        this.dataLimitingMessage = `${this.lang()?.DataLimitOn} (${this.lang()?.Last} 30 ${this.lang()?.Days})`;
                     } else if (
                        this.currentUser.userInfo.dataLimitingNumberOfMonths > 1
                     ) {
                        this.dataLimitingMessage = `${this.lang()?.DataLimitOn} (${this.lang()?.Last} ${this.currentUser.userInfo.dataLimitingNumberOfMonths} ${this.lang()?.Months})`;
                     }
                     const element = document.querySelector(
                        ".bottom-banner",
                     ) as HTMLElement;
                     element.classList.add("show");
                  }
               }

               // Wait for user info before registering for push notifications
               this.pushNotificationService.register({
                  customerID: this.customerID,
                  userID: this.userID,
               });
            }
         },
      );

      this.setWhitelabelContent();
      this.alerts$ = this.alertService.getAlerts();
      this.subscriptions.push(loginWatchVarSub, routeSecurity);

      this.litePropsStorageSyncService.queryCurrentUserID().then((liteUserID) => {
         if (
            liteUserID &&
            this.manageUser.getCurrentUser()?.gUserID &&
            liteUserID !== this.manageUser.getCurrentUser()?.gUserID
         ) {
            syncQueue.clearQueue();
            this.litePropsStorageSyncService.syncLitePropsReset();
            this.litePropsStorageSyncService.syncCurrentUserID(
               this.manageUser.getCurrentUser()?.gUserID,
            );
         } else if (
            liteUserID &&
            this.manageUser.getCurrentUser()?.gUserID &&
            liteUserID === this.manageUser.getCurrentUser()?.gUserID
         ) {
            firstValueFrom(syncQueue.count$).then((jobCount) => {
               if (jobCount > 0) this.router.navigate(["/lite/tasks"]);
            });
         }
      });
   }

   public ngOnDestroy() {
      this.manageObservables.removeManySubscriptions([this.customerIDSub]);
      for (const sub of this.subscriptions) {
         sub.unsubscribe();
      }
   }

   private isMobileWorkRequest(): boolean {
      // Skip data refresh for mobile users on work request pages because fetching all data was causing crashes on mobile work requests for users.
      return this.isWorkRequestPage() && this.mobile;
   }

   private preventAccountSharing() {
      // make sure currentUser obj available,
      this.refreshService.dataInitialized().subscribe(() => {
         const currentUser = this.manageUser.getCurrentUser();
         // internal users / external users and special customers don't get this sharing check
         if (currentUser.userInfo.remmah == 1) {
            return;
         }
         if (
            currentUser.userInfo.userInternal == 1 ||
            currentUser.userInfo.userWorkOrderUser == 1 ||
            currentUser.userInfo.enableAccountSharing == 1
         ) {
            return;
         }
         this.userName = `${currentUser.userInfo.fName} ${currentUser.userInfo.lName}`;
         this.hammerService.start();
         this.hammerService.hammer$.subscribe(() => {
            //you have 30s to decide if you want to stay logged in
            if (this.showHammerModal) {
               //already hammered
               return;
            }
            this.hammers = this.popHammerModal();
         });
         this.hammerService.stopHammer$.subscribe(() => {
            //another tab has decided this browser session will stay logged in
            if (!this.showHammerModal) {
               //already not hammered
               return;
            }
            this.stopHammer();
         });
      });
   }

   protected closeAlert(alert: Alert): void {
      this.alertService.closeAlert(alert);
   }
   protected alertLinkClicked(alertLink: string | undefined): void {
      if (alertLink) {
         this.alertService.setAlertLinkClicked(alertLink);
      }
   }

   /**
    * opens the modal, starts a timer and an interval then returns refs to
    * timer and interval so they can be cancelled if necessary
    */
   private popHammerModal(): { interval: Timer; timer: Timer } {
      this.showHammerModal = true;
      const timerLength = 30;
      let timeElapsed = 0;

      this.timeLeft = timerLength;

      const hammerInterval = setInterval(() => {
         timeElapsed += 1;
         this.timeLeft = timerLength - timeElapsed;
      }, 1000);

      const hammerTimer = setTimeout(() => {
         this.manageLogin.logout();
      }, timerLength * 1000);

      return { interval: hammerInterval, timer: hammerTimer };
   }

   private stopHammer() {
      if (this.hammers !== undefined) {
         clearTimeout(this.hammers.timer);
         clearInterval(this.hammers.interval);
      }
      this.showHammerModal = false;
   }

   public stopHammerClick() {
      this.stopHammer();
      // this user has decided this session should remain logged in,
      // so tell the other session(s) it is time to logout
      this.hammerService.passHammer();
   }

   public toggleShowMobileSideMenu() {
      this.showMobileSideMenu = !this.showMobileSideMenu;
   }

   newWO = (asset) => {
      if (
         !this.credService.isAuthorized(
            asset.locationID,
            this.credService.Permissions.StartNewTasks,
         )
      ) {
         this.alertService.addAlert(this.lang()?.missingCred43 ?? "", "danger", 10000);
         return;
      }

      const instance = this.modalService.open(SetupWorkOrder);

      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: this.lang()?.SetupWorkOrderMsg,
            title: this.lang()?.SetupWorkOrder,
            data: {
               WO: true,
               assetID: asset.assetID,
               locationID: asset.locationID,
            },
         },
      };
      instance.result.then((data) => {
         if (data) {
            this.loaded = false;

            this.manageTask
               .goLiveWorkOrder(
                  data.WO,
                  data.profileID,
                  data.userID,
                  data.multiUsers,
                  data.timestamp,
                  0,
                  data.timeOfDay,
                  data.startDate,
                  data.startDateTimeOfDay,
                  this.manageUser,
                  this.manageProfile,
               )
               .then(
                  (answer) => {
                     this.loaded = true;
                     if (answer.data.success == true) {
                        const userID = answer.data.task.userID;
                        const profileID = answer.data.task.profileID;

                        const currentUser = this.manageUser.getCurrentUser();
                        const profiles = currentUser.profileLoc;
                        let assignedToProfileIBelongTo = false;
                        for (const profile of profiles) {
                           if (
                              profile.profileID == profileID &&
                              profile.locationID == asset.locationID
                           ) {
                              assignedToProfileIBelongTo = true;
                           }
                        }

                        if (userID == currentUser.gUserID || assignedToProfileIBelongTo) {
                           this.viewTask(data.WO.task);
                           this.alertService.addAlert(
                              this.lang()?.successMsgWorkOrderStarted ?? "",
                              "success",
                              5000,
                           );
                        } else {
                           this.alertService.addAlert(
                              this.lang()?.WorkOrderSuccessfullyStartedAndNotify ?? "",
                              "success",
                              5000,
                           );
                        }
                     } else {
                        this.alertService.addAlert(
                           this.lang()?.errorMsg ?? "",
                           "danger",
                           10000,
                        );
                     }
                  },
                  () => {
                     this.alertService.addAlert(
                        this.lang()?.errorMsg ?? "",
                        "danger",
                        10000,
                     );
                  },
               );
         }
      });
   };

   viewTask = (task) => {
      const instance = this.modalService.open(PopTask);

      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            data: {
               checklistID: task.checklistID,
               editable: true,
            },
         },
      };
   };

   private setWhitelabelContent() {
      const favicon16 = document.querySelector("#favicon16");
      const favicon32 = document.querySelector("#favicon32");
      const favicon96 = document.querySelector("#favicon96");
      const favicon16Url = this.whiteLabelService.favicon(16);
      const favicon32Url = this.whiteLabelService.favicon(32);
      const favicon96Url = this.whiteLabelService.favicon(96);
      if (favicon16 instanceof HTMLLinkElement && favicon16Url) {
         favicon16.href = favicon16Url;
      }
      if (favicon32 instanceof HTMLLinkElement && favicon32Url) {
         favicon32.href = favicon32Url;
      }
      if (favicon96 instanceof HTMLLinkElement && favicon96Url) {
         favicon96.href = favicon96Url;
      }
      this.titleService.setTitle(this.whiteLabelService.title());
   }

   protected showGlobalSearch(): void {
      const instance = this.modalService.open(GlobalSearchComponent);
      this.paramsService.params = {
         modalInstance: instance,
      };
   }

   protected showDesktopGlobalSideNav(): boolean {
      return (
         !this.mobile &&
         Boolean(this.currentUser) &&
         this.currentUser !== "none" &&
         Boolean(this.lang()) &&
         !this.isExternalUser
      );
   }
}
