import { NgClass } from "@angular/common";
import type { OnDestroy, OnInit } from "@angular/core";
import { inject, Component, Input, computed } from "@angular/core";
import { PaginationComponent, PanelComponent } from "@limblecmms/lim-ui";
import { ManageLang } from "src/app/languages/services/manageLang";
import { ManageLocation } from "src/app/locations/services/manageLocation";
import { PoListItem } from "src/app/purchasing/pos/poListItemElement/poListItem.element.component";
import { ManagePO } from "src/app/purchasing/services/managePO";
import type { PurchaseOrder } from "src/app/purchasing/types/purchase-order/purchase-order.types";
import { SortColumn } from "src/app/shared/components/global/sortColumnModal/sortColumn.element.component";
import { BetterDate } from "src/app/shared/services/betterDate";
import { ManageFilters } from "src/app/shared/services/manageFilters";
import { assert } from "src/app/shared/utils/assert.utils";
import { LimbleMap } from "src/app/shared/utils/limbleMap";
import { Lookup } from "src/app/shared/utils/lookup";
import { ManageUser } from "src/app/users/services/manageUser";
import { ManageVendor } from "src/app/vendors/services/manageVendor";

@Component({
   selector: "global-search-po-list",
   templateUrl: "./global-search-po-list.component.html",
   styleUrls: ["./global-search-po-list.component.scss"],
   standalone: true,
   imports: [PanelComponent, SortColumn, NgClass, PoListItem, PaginationComponent],
})
export class GlobalSearchPoListComponent implements OnInit, OnDestroy {
   @Input() search: string = "";
   @Input() poIDs?: Array<number>;

   protected readonly columns: Array<{
      column: string;
      columnName: string;
      columnWidthClass: string;
   }>;
   public purchaseOrders: Lookup<"poID", PurchaseOrder> = new Lookup("poID");
   public sort: keyof PurchaseOrder = "poNumber";
   public page: number = 1;
   public itemsPerPage: number;
   public posInView: Lookup<"poID", PurchaseOrder> = new Lookup("poID");
   protected purchaseOrderSearchHints: LimbleMap<number, string> = new LimbleMap();

   private readonly manageUser = inject(ManageUser);
   private readonly managePo = inject(ManagePO);
   private readonly manageFilters = inject(ManageFilters);
   private readonly manageLocation = inject(ManageLocation);
   private readonly manageVendor = inject(ManageVendor);
   private readonly betterDate = inject(BetterDate);
   private readonly manageLang = inject(ManageLang);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public constructor() {
      this.itemsPerPage =
         this.manageUser.getCurrentUser()?.userInfo.userUIPreferences.itemsPerPage ?? 10;
      this.columns = [
         {
            column: "poNumber",
            columnName: this.lang().PONumber,
            columnWidthClass: "col-md-1",
         },
         {
            column: "total",
            columnName: this.lang().Total,
            columnWidthClass: "col-md-1",
         },
         {
            column: "poPartsStr",
            columnName: this.lang().POItems,
            columnWidthClass: "col-md-2",
         },
         {
            column: "vendorID",
            columnName: this.lang().Vendor,
            columnWidthClass: "col-md-2",
         },
         {
            column: "date",
            columnName: this.lang().PODate,
            columnWidthClass: "col-md-1",
         },
         {
            column: "deliveryDate",
            columnName: this.lang().PODeliveryDate,
            columnWidthClass: "col-md-1",
         },
         {
            column: "state",
            columnName: this.lang().POStatus,
            columnWidthClass: "col-md-1",
         },
         {
            column: "userID",
            columnName: this.lang().AssignedTo,
            columnWidthClass: "col-md-2",
         },
         {
            column: "options",
            columnName: this.lang().Options,
            columnWidthClass: "col-md-1",
         },
      ];
   }

   public ngOnInit(): void {
      if (this.search === undefined) {
         throw new Error("required input `search` is not defined");
      }
      if (this.poIDs === undefined) {
         throw new Error("required input `poIDs` is not defined");
      }
      this.setPos();
   }

   protected pageChange(newPage: number): void {
      this.page = newPage;
      this.setPosInView();
   }

   protected sortChange(newBind: keyof PurchaseOrder): void {
      this.sort = newBind;
      this.setPosInView();
   }

   private setPos(): void {
      assert(this.poIDs !== undefined);
      const purchaseOrders = this.poIDs
         .map((poID) => this.managePo.getPurchaseOrder(poID))
         .filter((purchaseOrder) => purchaseOrder !== undefined);
      this.purchaseOrders = new Lookup("poID", purchaseOrders);
      this.setPosInView();
   }

   private setPosInView(): void {
      this.posInView = this.purchaseOrders
         .orderBy(this.sort)
         .slice((this.page - 1) * this.itemsPerPage, this.page * this.itemsPerPage);
      this.addSearchHintsForPos();
   }

   protected addSearchHintsForPos(): void {
      this.manageFilters.filterPurchaseOrdersToSearch(
         this.search,
         this.posInView,
         this.purchaseOrderSearchHints,
         this.managePo,
         this.manageLocation,
         this.manageVendor,
         this.betterDate,
      );
   }

   // TODO(LIM-6029): remove this after plaid
   public ngOnDestroy(): void {
      this.managePo.resetSearchHints(this.purchaseOrders);
   }
}
