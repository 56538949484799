import { NgClass } from "@angular/common";
import type { OnDestroy, OnInit } from "@angular/core";
import { inject, Component, Input, computed, ChangeDetectorRef } from "@angular/core";
import { FormControl, ReactiveFormsModule } from "@angular/forms";
import { ActivatedRoute, NavigationEnd, Router, RouterLink } from "@angular/router";
import {
   IconComponent,
   isNativeMobileApp,
   PopoverDirective,
   SearchBoxComponent,
   TextButtonComponent,
   TooltipDirective,
} from "@limblecmms/lim-ui";
import axios from "axios/dist/axios";
import type { Subscription } from "rxjs";
import { debounceTime, filter, first, forkJoin, fromEvent, merge, tap } from "rxjs";
import { ManageDashboard } from "src/app/dashboards/manageDashboard";
import { UserImage } from "src/app/files/components/userImage/userImage.element.component";
import { ManageLang } from "src/app/languages/services/manageLang";
import { ManageLocation } from "src/app/locations/services/manageLocation";
import { PushNotificationService } from "src/app/mobile/push-notifications/push-notification.service";
import { locationRoutes } from "src/app/shared/components/app-housing.routes";
import { AccountManagementPopover } from "src/app/shared/components/global/global-nav/account-management-popover/account-management-popover.component";
import { LocationHierarchy } from "src/app/shared/components/global/global-nav/location-hierarchy/location-hierarchy";
import { NavLocationComponent } from "src/app/shared/components/global/global-nav/nav-location/nav-location.component";
import { NavRegionComponent } from "src/app/shared/components/global/global-nav/nav-region/nav-region.component";
import { NotificationsPopover } from "src/app/shared/components/global/global-nav/notifications-popover/notifications-popover.element.component";
import type { IsFeatureEnabledMap } from "src/app/shared/services/feature-flags/feature.types";
import { ManageFeatureFlags } from "src/app/shared/services/feature-flags/manageFeatureFlags";
import { Flags, LegacyLaunchFlagsService } from "src/app/shared/services/launch-flags";
import { ManageObservables } from "src/app/shared/services/manageObservables";
import { ManageUtil } from "src/app/shared/services/manageUtil";
import { assert } from "src/app/shared/utils/assert.utils";
import { PermissionID } from "src/app/users/schemata/users/self/credentials/permission.enum";
import { CredService } from "src/app/users/services/creds/cred.service";
import { ManageLogin } from "src/app/users/services/manageLogin";
import { ManageUser } from "src/app/users/services/manageUser";

@Component({
   selector: "global-nav",
   templateUrl: "./global-nav.component.html",
   styleUrls: ["./global-nav.component.scss", "./locationsSideBar.scss"],
   standalone: true,
   imports: [
      NgClass,
      RouterLink,
      TooltipDirective,
      IconComponent,
      NotificationsPopover,
      PopoverDirective,
      UserImage,
      AccountManagementPopover,
      TextButtonComponent,
      SearchBoxComponent,
      NavLocationComponent,
      NavRegionComponent,
      ReactiveFormsModule,
   ],
})
export class GlobalNavComponent implements OnInit, OnDestroy {
   @Input() showFeatureRequestBoard: boolean = false;

   public noSearchResults: boolean;
   public namedRegionsCount?: number;
   public disabledNamedRegionsCount?: number;
   protected currentUser;
   protected cusActive: boolean = false;
   protected currentDashboard?: number;
   protected currentMap?: number;
   public showWhatsNew: boolean;
   public locationsCount?: number;
   private buildDataSubscription: Subscription | undefined;
   public hierarchy?: LocationHierarchy;
   public disabledHierarchy?: LocationHierarchy | undefined;
   public isLocationSidebarOpen: boolean = false;
   protected featureMaps: 0 | 1 = 0;
   protected featureFuse: 0 | 1 = 0;
   protected isSuperUser: boolean = false;
   private readonly routeChangeSubscription: Subscription;
   private readonly logoutShortcutSubscription: Subscription;
   protected currentRouteName: string | undefined;
   private featureMultipleLocations: boolean = false;
   private readonly manageFeatureFlagsSub: Subscription;
   protected creds: Partial<Record<PermissionID, boolean>> = {};
   protected PermissionID = PermissionID;
   public searchControl: FormControl = new FormControl<string>("");
   public readonly searchSubscription: Subscription;
   private fullHierarchy?: LocationHierarchy;
   protected isAssetTemplatesEnabled: boolean | undefined;

   private readonly manageLocation = inject(ManageLocation);
   private readonly manageDashboard = inject(ManageDashboard);
   private readonly manageLogin = inject(ManageLogin);
   private readonly manageObservables = inject(ManageObservables);
   private readonly manageUser = inject(ManageUser);
   private readonly manageUtil = inject(ManageUtil);
   private readonly router = inject(Router);
   private readonly route = inject(ActivatedRoute);
   private readonly credService = inject(CredService);
   private readonly manageFeatureFlags = inject(ManageFeatureFlags);
   private readonly manageLang = inject(ManageLang);
   private readonly launchFlagsService = inject(LegacyLaunchFlagsService);
   private readonly cdr = inject(ChangeDetectorRef);
   private readonly pushNotificationService = inject(PushNotificationService);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public constructor() {
      this.routeChangeSubscription = this.router.events
         .pipe(filter((event): event is NavigationEnd => event instanceof NavigationEnd))
         .subscribe(() => {
            assert(this.route.firstChild !== null);
            const routeName = this.route.firstChild.snapshot.data.routeName;
            if (this.currentRouteName && this.currentRouteName === routeName) {
               return;
            }
            this.currentRouteName = routeName;
            this.setLocationSidebarState(routeName);
         });

      if (this.route.firstChild !== null) {
         const routeName = this.route.firstChild.snapshot.data.routeName;
         this.currentRouteName = routeName;
         this.setLocationSidebarState(routeName);
      }

      this.manageFeatureFlagsSub = this.manageFeatureFlags.features$.subscribe(
         (isFeatureEnabledMap: IsFeatureEnabledMap) => {
            this.featureMultipleLocations = isFeatureEnabledMap.featureMultipleLocations;
         },
      );

      this.noSearchResults = false;
      this.showWhatsNew = true;

      const loginWatchVar$ = this.manageUser.currentUserChanges$.pipe(
         filter((currentUser) => currentUser !== undefined && currentUser !== "none"),
         tap((currentUser) => {
            this.currentUser = currentUser;
            if (this.currentUser?.userInfo === undefined) return;
            this.featureMaps = currentUser.userInfo.featureMaps;
            this.featureFuse = currentUser.userInfo.featureFuse;
            if (this.currentUser.userInfo.customerActive === 1) {
               this.cusActive = true;
            } else {
               this.cusActive = false;
            }

            if (this.currentUser.userInfo.userInternal === 1) {
               this.cusActive = true;
            }

            this.currentDashboard =
               this.currentUser.userInfo.userUIPreferences.currentDashboardID || 0;

            this.currentMap =
               this.currentUser.userInfo.userUIPreferences.currentMapID || 0;

            this.isSuperUser = this.manageUtil.checkIfSuperUser(currentUser);

            this.creds = {
               [PermissionID.ManageLocations]: this.credService.checkCredGlobal(
                  PermissionID.ManageLocations,
               ),
               [PermissionID.ManageRoles]: this.credService.checkCredGlobal(
                  PermissionID.ManageRoles,
               ),
               [PermissionID.ViewGlobalDashboard]: this.credService.checkCredAnywhere(
                  PermissionID.ViewGlobalDashboard,
               ),
               [PermissionID.ViewCustomDashboards]: this.credService.checkCredAnywhere(
                  PermissionID.ViewCustomDashboards,
               ),
               [PermissionID.ViewManageUsers]: this.credService.checkCredAnywhere(
                  PermissionID.ViewManageUsers,
               ),
               [PermissionID.SetupAndConfigureWorkRequestPortal]:
                  this.credService.checkCredAnywhere(
                     PermissionID.SetupAndConfigureWorkRequestPortal,
                  ),
               [PermissionID.ViewMaps]: this.credService.checkCredAnywhere(
                  PermissionID.ViewMaps,
               ),
            };

            this.setBadgeCount(currentUser);
         }),
      );

      const locationWatchVar$ = this.manageObservables.getObservable("locationWatchVar");
      const regionWatchVar$ = this.manageObservables.getObservable("regionWatchVar");

      if (locationWatchVar$ === undefined || regionWatchVar$ === undefined) {
         throw new Error("Could not get required observables");
      }

      forkJoin([
         locationWatchVar$.pipe(first((val) => Number(val) > 0)),
         regionWatchVar$.pipe(first((val) => Number(val) > 0)),
         loginWatchVar$.pipe(first()),
      ]).subscribe(() => {
         this.buildDataSubscription = merge(
            locationWatchVar$,
            regionWatchVar$,
            loginWatchVar$,
         )
            .pipe(debounceTime(50))
            .subscribe(() => {
               this.buildData();
            });
      });

      this.logoutShortcutSubscription = fromEvent<KeyboardEvent>(document, "keydown")
         .pipe(filter((event) => event.ctrlKey && event.shiftKey && event.key === "l"))
         .subscribe(() => {
            this.manageLogin.logout();
         });

      this.searchSubscription = this.searchControl.valueChanges
         .pipe(debounceTime(750))
         .subscribe(() => {
            this.buildData();
         });
   }

   public ngOnInit() {
      this.initialize();
   }

   private async initialize() {
      this.isAssetTemplatesEnabled = await this.launchFlagsService.isEnabled(
         Flags.ASSET_TEMPLATES,
      );
   }

   // We are calling detect changers here for widget resizing to fit
   protected toggleLocationMenu(): void {
      this.isLocationSidebarOpen = !this.isLocationSidebarOpen;
      this.cdr.detectChanges();
      this.manageDashboard.isCollapsedObs$.next(null);
   }

   private setLocationSidebarState(routeName?: string) {
      if (this.locationsCount === 1) {
         // Initialize the sidebar to be open if there is only one location,
         // otherwise preserve the previous state
         this.isLocationSidebarOpen ??= true;
      } else {
         // Sets the open state when the route exists
         if (routeName != null) {
            this.isLocationSidebarOpen = locationRoutes.includes(routeName);
         }
      }
   }

   public ngOnDestroy(): void {
      this.buildDataSubscription?.unsubscribe();
      this.routeChangeSubscription.unsubscribe();
      this.manageFeatureFlagsSub.unsubscribe();
      this.logoutShortcutSubscription.unsubscribe();
      this.searchSubscription.unsubscribe();
   }

   private buildData(): void {
      const currentUser = this.manageUser.getCurrentUser();
      const isSuperUser = currentUser.userInfo.isSuperUser === 1;

      this.fullHierarchy = new LocationHierarchy(
         this.manageLocation.getRegions(),
         this.manageLocation.getLocations(),
         { removeEmptyRegions: true },
      );
      this.namedRegionsCount = this.fullHierarchy.allRegions().size;
      this.locationsCount = this.fullHierarchy.allLocations().size;
      this.hierarchy = this.fullHierarchy.search(this.searchControl.value ?? "");

      if (this.locationsCount === 1) {
         // To initialize the sidebar to be open when there is only one location
         this.setLocationSidebarState();
      }

      //For super admins that have deactivated locations, separate disabled into a separate tree
      if (isSuperUser && !this.featureMultipleLocations) {
         this.disabledHierarchy = new LocationHierarchy(
            this.manageLocation.getRegions(),
            this.manageLocation.getInactiveLocations(),
            { removeEmptyRegions: true },
         );
         this.disabledNamedRegionsCount = this.disabledHierarchy.allRegions().size;
      } else {
         this.disabledHierarchy = undefined;
      }

      if (
         this.hierarchy.nodeCount() === 0 &&
         (this.disabledHierarchy === undefined ||
            this.disabledHierarchy?.nodeCount() === 0)
      ) {
         this.noSearchResults = true;
      } else {
         this.noSearchResults = false;
      }
   }

   protected loginFuse(): void {
      const url = window.location.href;
      //Send to Fuse the user logging in
      const dObj = {
         "fName": this.currentUser.userInfo.fName,
         "lName": this.currentUser.userInfo.lName,
         "email": this.currentUser.userInfo.userLoginName,
         "phone": this.currentUser.userInfo.userPhone,
         "plan_name": this.currentUser.userInfo.customerPlan,
         "Company Name": this.currentUser.userInfo.customerName,
         "customer_subscription_status": this.currentUser.userInfo.customerStatus,
         "custom_user_logins": this.currentUser.userInfo.logins,
         "customerID": Number(this.currentUser.userInfo.customerID),
         "userID": Number(this.currentUser.userInfo.userID),
         "custom_review_segment": (Number(this.currentUser.userInfo.customerID) % 12) + 1,
         "customerActive": this.currentUser.userInfo.customerActive,
         "env": "dev",
      };
      if (url.includes("limblecmms.com")) {
         dObj.env = "production";

         axios({
            method: "POST",
            url: "https://hook.fuse.limblecmms.com/wngy6wgewauqgu55h4985ielfsc3ewtd",
            data: dObj,
         });
         window.open("https://fuse.limblecmms.com", "_blank");
      } else {
         axios({
            method: "POST",
            url: "https://hook.fuse.limblecmms.com/wngy6wgewauqgu55h4985ielfsc3ewtd",
            data: dObj,
         });
         window.open("https://fuse.limblestaging.com", "_blank");
      }
   }

   private setBadgeCount(currentUser) {
      if (isNativeMobileApp() === false) return;

      const unreadCount =
         currentUser.notifications?.filter((notification) => notification.visited === 0)
            .length ?? 0;
      this.pushNotificationService.setBadgeCount(unreadCount);
   }
}
