import { AsyncPipe, DatePipe, NgStyle } from "@angular/common";
import type { OnDestroy, Signal, WritableSignal } from "@angular/core";
import { inject, Component, HostBinding, Input, computed } from "@angular/core";
import { Router, RouterLink } from "@angular/router";
import {
   IconComponent,
   ModalService,
   LoadingAnimationComponent,
   PopoverService,
   ScrollContainerComponent,
   SecondaryButtonComponent,
   manageDemo,
} from "@limblecmms/lim-ui";
import type { Observable, Subscription } from "rxjs";
import { UserImage } from "src/app/files/components/userImage/userImage.element.component";
import { ManageLang } from "src/app/languages/services/manageLang";
import {
   LiteEnabledState,
   LiteEnablementService,
} from "src/app/lite/lite-enablement.service";
import { OfflinePrepService } from "src/app/lite/local-db/offline-prep.service";
import { OfflinePrepState } from "src/app/lite/local-db/offline-prep.service.types";
import type { AppReviewInfo } from "src/app/shared/components/global/global-nav/account-management-popover/account-management-popover.types";
import { ParamsService } from "src/app/shared/services/params.service";
import { RefreshService } from "src/app/shared/services/refresh.service";
import { UpdateService } from "src/app/shared/services/update/update.service";
import { DateUtilsService } from "src/app/shared/utils/date-utils.service";
import { ManageSubscription } from "src/app/subscriptions/services/manageSubscription";
import { ManageTrials } from "src/app/subscriptions/services/manageTrials";
import { ManualResetPw } from "src/app/users/components/manualResetPwModal/manualResetPw.modal.component";
import { PopUserDetails } from "src/app/users/components/popUserDetailsModal/popUserDetails.modal.component";
import { CredService } from "src/app/users/services/creds/cred.service";
import { ManageLogin } from "src/app/users/services/manageLogin";
import { ManageUser } from "src/app/users/services/manageUser";

@Component({
   selector: "account-management-popover",
   templateUrl: "./account-management-popover.component.html",
   styleUrls: ["./account-management-popover.component.scss"],
   standalone: true,
   imports: [
      IconComponent,
      NgStyle,
      UserImage,
      ScrollContainerComponent,
      RouterLink,
      SecondaryButtonComponent,
      AsyncPipe,
      DatePipe,
      LoadingAnimationComponent,
   ],
})
export class AccountManagementPopover implements OnDestroy {
   @Input() public currentUser;
   @Input() public showFeatureRequestBoard = false;
   @Input() public position: string = "bottom right";

   @HostBinding("class") public readonly classes = "scroll-height-inheritance";

   public version: WritableSignal<string | null>;
   public isLiteEnabled: Signal<boolean>;
   protected billingCred: boolean = false;
   protected demo;
   protected planName: string = "";
   protected isBasicPlan$: Observable<boolean>;
   private readonly currentUserSub: Subscription;
   protected readonly OfflinePrepState = OfflinePrepState;

   private readonly manageLogin = inject(ManageLogin);
   private readonly modalService = inject(ModalService);
   private readonly paramsService = inject(ParamsService);
   private readonly popoverService = inject(PopoverService);
   private readonly manageUser = inject(ManageUser);
   private readonly credService = inject(CredService);
   private readonly manageTrial = inject(ManageTrials);
   private readonly manageSubscription = inject(ManageSubscription);
   protected readonly refreshService = inject(RefreshService);
   protected readonly router = inject(Router);
   public readonly litePrepState = inject(OfflinePrepService).state;
   protected readonly dateUtil = inject(DateUtilsService);
   private readonly updateService = inject(UpdateService);
   private readonly manageLang = inject(ManageLang);

   protected readonly lang = this.manageLang.lang;

   public constructor() {
      this.version = this.updateService.localVersion;
      this.demo = manageDemo.demo;

      this.currentUserSub = this.manageUser.currentUserChanges$.subscribe(() => {
         this.billingCred = this.credService.checkCredAnywhere(
            this.credService.Permissions.ViewSubscription,
         );
      });

      if (this.manageSubscription.getBilling()) {
         // Don't hit Chargify if we already have the data
         this.getSubscriptionInfo();
      } else {
         this.manageSubscription.getChargifyData().then(() => {
            this.getSubscriptionInfo();
         });
      }

      this.isBasicPlan$ = this.manageSubscription.isBasicPlan$;

      const liteEnablementService = inject(LiteEnablementService);
      this.isLiteEnabled = computed(
         () => liteEnablementService.liteEnabled() === LiteEnabledState.Enabled,
      );
   }

   public ngOnDestroy() {
      this.currentUserSub.unsubscribe();
   }

   public resyncCache = async () => {
      await this.refreshService.refreshData({ notify: false });
   };

   public popUser = () => {
      const modalRef = this.modalService.open(PopUserDetails);
      modalRef.setInput("user", this.currentUser);
   };

   public changePassword = () => {
      const instance = this.modalService.open(ManualResetPw);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {},
      };
   };

   public logout = () => {
      this.manageLogin.logout();
   };

   protected getSubscriptionInfo() {
      const billingInfo = this.manageSubscription.getBilling();

      if (billingInfo.chargifyPlan?.component_handle) {
         try {
            this.planName = this.manageTrial.getTrialDisplayInfo(
               billingInfo.chargifyPlan?.component_handle,
            ).tierText;
         } catch (error) {
            this.planName = "";
         }
      }
   }

   protected openUpgradePage() {
      window.open("https://limblecmms.com/pricing/", "_blank");
   }

   protected refreshPage(): void {
      this.refreshService.refreshData();
   }

   protected close(): void {
      this.popoverService.destroyAllPopoverComponents();
   }

   protected openFeatureRequestBoard(): void {
      window.open("https://portal.productboard.com/zodsn5nylisgwqkcbx9rh6kp", "_blank");
   }

   protected openHelpCenter(): void {
      window.open("https://help.limblecmms.com/en", "_blank");
   }

   protected startG2Review(): void {
      this.router.navigate(["/leave-review"]);
   }

   protected navigateToLite(): void {
      this.popoverService.destroyAllPopoverComponents();
      this.router.navigate(["/lite"]);
   }

   protected showLeaveReview(): boolean {
      if (this.currentUser === undefined || this.currentUser.workOrderUser === 1)
         return false;

      const appReviewInfo: AppReviewInfo = this.currentUser.userInfo?.appReviewInfo;
      const lastNPSReview =
         appReviewInfo?.lastNPS9Review ?? appReviewInfo?.lastNPS10Review;

      return this.dateUtil.isWithin30Days(lastNPSReview);
   }
}
