import { Component, computed, inject } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import {
   IconComponent,
   ModalService,
   PrimaryButtonComponent,
   SecondaryButtonComponent,
} from "@limblecmms/lim-ui";
import { ManageLang } from "src/app/languages/services/manageLang";
import {
   LiteEnabledState,
   LiteEnablementService,
} from "src/app/lite/lite-enablement.service";
import { OfflinePrepService } from "src/app/lite/local-db/offline-prep.service";
import { OfflinePrepState } from "src/app/lite/local-db/offline-prep.service.types";
import { PopTask } from "src/app/tasks/components/popTaskModal/popTask.modal.component";

@Component({
   selector: "connection-lost",
   standalone: true,
   templateUrl: "./connection-lost.component.html",
   styleUrls: ["./connection-lost.component.scss"],
   imports: [PrimaryButtonComponent, SecondaryButtonComponent, IconComponent],
})
export class ConnectionLostComponent {
   protected isLiteEnabled = inject(LiteEnablementService).liteEnabled;
   protected lang = inject(ManageLang).lang;
   protected readonly LiteEnabledState = LiteEnabledState;
   public readonly litePrepState = inject(OfflinePrepService).state;
   protected isLitePrepared = computed(
      () =>
         this.litePrepState() === OfflinePrepState.Prepared ||
         this.litePrepState() === OfflinePrepState.PreparedButLimited,
   );
   protected isLimited = computed(
      () => this.litePrepState() === OfflinePrepState.PreparedButLimited,
   );
   private readonly router = inject(Router);
   private readonly route = inject(ActivatedRoute);
   private readonly modalService = inject(ModalService);

   protected goOffline() {
      if (this.isLiteEnabled() !== LiteEnabledState.Enabled || !this.isLitePrepared())
         return;

      //close all active popovers
      const popovers = document.querySelectorAll("lim-ui-popover");
      popovers.forEach((popover) => {
         popover.remove();
      });

      const liteRoute = this.getLiteRoute();
      this.router.navigate([liteRoute]);
   }

   private getLiteRoute(): string {
      const currentTaskID = this.getActiveTaskID();
      return currentTaskID === undefined ? "/lite/tasks" : `/lite/task/${currentTaskID}`;
   }

   private getActiveTaskID(): number | undefined {
      const activeModalComponentInstance =
         this.modalService.getActiveModal()?.componentInstanceSafe;

      if (activeModalComponentInstance instanceof PopTask) {
         return activeModalComponentInstance.task?.checklistID;
      }

      const currentRoute = this.route.firstChild?.snapshot;
      if (currentRoute?.params?.taskID) {
         return Number(currentRoute.params.taskID);
      }

      return undefined;
   }
}
