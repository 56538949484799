import { NgClass } from "@angular/common";
import type { AfterViewInit, OnDestroy, OnInit } from "@angular/core";
import {
   inject,
   ChangeDetectorRef,
   Component,
   EventEmitter,
   Input,
   Output,
   ViewChildren,
   viewChildren,
} from "@angular/core";
import { FormControl } from "@angular/forms";
import { IconComponent } from "@limblecmms/lim-ui";
import type { Subscription } from "rxjs";
import type {
   Location,
   LocationHierarchy,
   Region,
} from "src/app/shared/components/global/global-nav/location-hierarchy/location-hierarchy";
import { NavLocationComponent } from "src/app/shared/components/global/global-nav/nav-location/nav-location.component";
import type { IsFeatureEnabledMap } from "src/app/shared/services/feature-flags/feature.types";
import { ManageFeatureFlags } from "src/app/shared/services/feature-flags/manageFeatureFlags";

@Component({
   selector: "nav-region",
   templateUrl: "./nav-region.component.html",
   styleUrls: ["./nav-region.component.scss"],
   standalone: true,
   imports: [NgClass, IconComponent, NavLocationComponent],
})
export class NavRegionComponent implements OnInit, AfterViewInit, OnDestroy {
   @Input({ required: true }) public region?: Region;
   @Input({ required: true }) public hierarchy?: LocationHierarchy;
   @Input() public isChild = false;
   @Input() public searchControl: FormControl = new FormControl<string>("");
   @Input() public regionIsDisabled = false;
   @Output() public readonly regionActive = new EventEmitter<boolean>();
   @ViewChildren(NavLocationComponent)
   public childLocations?: Array<NavLocationComponent>;
   public childRegions = viewChildren(NavRegionComponent);

   private active: boolean = false;
   public unfoldRegion: boolean = false;
   private readonly manageFeatureFlagsSub: Subscription;
   private featureMultipleLocations: boolean = false;

   private readonly changeDetectorRef = inject(ChangeDetectorRef);

   public constructor() {
      this.manageFeatureFlagsSub = inject(ManageFeatureFlags).features$.subscribe(
         (isFeatureEnabledMap: IsFeatureEnabledMap) => {
            this.featureMultipleLocations = isFeatureEnabledMap.featureMultipleLocations;
         },
      );
   }

   public ngOnInit() {
      if (this.regionIsDisabled) {
         this.unfoldRegion = true;
      }
   }

   public ngAfterViewInit(): void {
      this.checkActivity();
      // Avoids NG0100 Error. https://angular.io/errors/NG0100
      this.changeDetectorRef.detectChanges();

      // Expand region if search is active and region has a match
      this.searchControl.valueChanges.subscribe((value) => {
         this.unfoldRegion = Boolean(value);
      });
   }

   public ngOnDestroy() {
      this.manageFeatureFlagsSub.unsubscribe();
   }

   public unfold() {
      if (this.regionIsDisabled && !this.featureMultipleLocations) {
         return;
      }
      this.unfoldRegion = !this.unfoldRegion;
   }

   public checkActivity(): void {
      const oldActive = this.active;
      this.active =
         (this.childLocations?.some((location) => location.activeRouteName) ?? false) ||
         (this.childRegions().some((region) => region.active) ?? false);
      this.unfoldRegion = this.active;
      if (oldActive !== this.active) {
         this.regionActive.emit(this.active);
      }
   }

   protected nodeIsLocation(node: Region | Location): node is Location {
      return "locationID" in node;
   }

   protected nodeIsRegion(node: Region | Location): node is Region {
      return "regionName" in node;
   }
}
